<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'用户'"
      :isInline="true"
    >
      <!-- <div slot="toolSub">
        <el-button
          type="success"
          :loading="bntLoading"
          icon="el-icon-plus"
          @click="synchronism"
          >同步默认商品</el-button
        >
      </div> -->
      <div class="search" slot="tool">
        <el-input
          placeholder="根据标题查询"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">标题</template>
        </el-input>

        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">类别</div>
          <el-select
            filterable
            v-model="searchQuery.typeId"
            clearable
            placeholder="根据类别筛选"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </app-list>
    <el-dialog
      :title="'重置密码'"
      :visible.sync="pswDialogVisible"
      width="400px"
      center
      :close-on-click-modal="false"
    >
      <div class="changeCon">
        <p>
          重置账号<b>{{ curUserName }}</b
          >的密码
        </p>

        <div style="margin-top: 10px">
          <el-input
            placeholder="请输入旧密码"
            v-model="oldPassword"
            type="passw"
            style="margin-bottom: 10px"
          >
          </el-input>
          <el-input
            placeholder="请输入新密码"
            v-model="password"
            type="passw"
            style="margin-bottom: 10px"
          >
          </el-input>
          <el-button
            type="primary"
            icon="el-icon-edit"
            style="width: 100%"
            @click="handleChangePassWord"
            >提交</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    let self = this;

    var numberCheck = (rule, value, callback) => {
      if (rule.field == "moneyMin") {
        this.formNum.min = value;
      }
      if (rule.field == "moneyMax") {
        this.formNum.max = value;
      }
      console.log(rule, value);
      const mesObj = {
        moneyMin: "请输入金额下限",
        moneyMax: "请输入金额上限",
        orderMax: "请输入订单阈值",
        orderWeight: "请输订单权重",
      };
      if (!value) {
        return callback(new Error(mesObj[rule.field]));
      }

      if (!/^\d+$/.test(value)) {
        callback(new Error("请输入数字值"));
      }
      if (rule.field == "moneyMax" && this.formNum.max < this.formNum.min) {
        callback(new Error("上限数值不允许小于下限"));
      } else {
        callback();
      }
    };
    return {
      pswDialogVisible: false,
      remote: api.common,
      password: "",
      oldPassword: "",
      curUserName: "",
      apiName: "/user",
      formNum: { max: 0, min: 0 },
      searchVal: "",
      jobList: [],
      typeList: [],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { name: "", typeId: "" },
      rules: {
        //传递给APPlist的表单验证

        username: [
          {
            required: true,
            message: "请输入账户名",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        orderMax: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: numberCheck,
          },
        ],
        orderWeight: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: numberCheck,
          },
        ],
        moneyMin: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: numberCheck,
          },
        ],
        moneyMax: [
          {
            required: true,
            trigger: ["blur", "change"],
            type: "number",
            validator: numberCheck,
          },
        ],
        roleId: [
          {
            required: true,
            message: "请选择角色",
            trigger: ["blur", "change"],
          },
        ],
        departmentId: [
          {
            required: true,
            message: "请选择部门",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "用户名",
          prop: "username",
          align: "center",
        },
        {
          label: "姓名",
          prop: "name",
          align: "center",
        },

        {
          label: "电话",
          prop: "phone",
          align: "center",
        },

        {
          label: "部门",
          prop: "departmentName",
          align: "center",
        },

        {
          label: "岗位",
          prop: "jobName",
          align: "center",
        },

        {
          label: "性别",
          prop: "sex",
          align: "center",
          formatter: function (row) {
            const statusMap = { 0: "未知", 1: "男", 2: "女" };
            return statusMap[row.sex];
          },
        },
        {
          label: "邮箱",
          prop: "mail",
          align: "center",
          width: 250,
        },

        {
          label: "角色",
          prop: "roleName",
          align: "center",
        },

        {
          label: "订单阈值",
          prop: "orderMax",
          align: "center",
          width: 100,
        },

        {
          label: "订单权重",
          prop: "orderWeight",
          align: "center",
          width: 100,
        },

        {
          label: "是否接单",
          prop: "isReceive",
          align: "center",
          type: "switch",
          formatter: function () {
            const switchInfo = {
              handler: function (row) {
                self.handleSwitch(row);
              },
            };

            return switchInfo;
          },
        },
        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "edit",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "delete",
                handler: function () {},
              },
              {
                text: "重置",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-key",
                type: "success",
                handler: function () {
                  self.changePsw(row);
                },
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "用户名",
          prop: "username",
          type: "creatOnlyInput",
        },
        {
          label: "密码",
          prop: "password",
          type: "creatOnlyInput",
          inputType: "passw",
          showPassword: true,
        },
        {
          label: "姓名",
          prop: "name",
          type: "input",
        },
        {
          label: "电话",
          prop: "phone",
          type: "input",
        },

        {
          label: "部门",
          prop: "departmentId",
          type: "cascader",
          selectData: [],
        },
        {
          label: "岗位",
          prop: "jobId",
          type: "select",
          selectData: [],
        },

        {
          label: "角色",
          prop: "roleId",
          type: "select",
          selectData: [],
        },
        {
          label: "邮箱",
          prop: "mail",
          type: "input",
        },
        {
          label: "支付宝",
          prop: "payAccountName",
          type: "input",
        },
        {
          label: "备注",
          prop: "remark",
          type: "input",
        },
        {
          label: "订单阈值",
          prop: "orderMax",
          type: "input",
        },
        {
          label: "订单权重",
          prop: "orderWeight",
          type: "input",
        },

        {
          label: "金额下限",
          prop: "moneyMin",
          type: "input",
        },

        {
          label: "金额上限",
          prop: "moneyMax",
          type: "input",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getRoleList();
    this.getJobList();
    this.getDepTree();
  },
  methods: {
    async handleChangePassWord() {
      if (this.password.length < 6) {
        this.$message.error("密码长度不得低于6位");
        return false;
      } else {
        let res = await api.commonPost({
          apiName: "/user/updatePassWord",
          password: this.password,
          oldPassword: this.oldPassword,
        });
        if (res.code == 200) {
          this.$message.success("重置密码成功");
          this.pswDialogVisible = false;
        }
      }
    },
    changePsw(item) {
      console.log(item);
      this.curUserName = item.username;
      this.pswDialogVisible = true;
    },
    async getJobList() {
      let res = await api.common.commonGet({ apiName: "/systemJob" });

      let array = [];
      res.data.data.forEach((element) => {
        array.push({
          value: element.id,
          label: element.jobName,
        });
      });
      this.formProps[5].selectData = array;
    },

    async getRoleList() {
      let res = await api.common.commonGet({ apiName: "/role" });
      let array = [];
      res.data.data.forEach((element) => {
        array.push({
          value: element.id,
          label: element.name,
        });
      });
      this.formProps[6].selectData = array;
    },
    handleSwitch(obj) {
      let newObj = Object.assign({}, obj, {
        apiName: "/user/updateIsReceive",
      });
      newObj.isReceive = parseInt(newObj.isReceive);
      api.common.commonPost(newObj);
    },

    async getDepTree() {
      let treeArr = [];
      await api.common
        .commonGet({ apiName: "/department/getTreeList" })
        .then((res) => {
          let arr = res.data;
          arr.forEach((item) => {
            if (item.children) {
              let children = [];
              item.children.forEach((items) => {
                children.push({
                  value: items.id,
                  label: items.name,
                });
              });
              treeArr.push({
                value: item.id,
                label: item.name,
                children: children,
              });
            } else {
              treeArr.push({
                value: item.id,
                label: item.name,
              });
            }

            this.formProps[4].selectData = treeArr;
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
